.description__content__container__userDropdown {
  width: 90% !important;
}

.description__content__container__dropDown__container {
  position: relative;
  width: 100%;
  margin-bottom: 10px;

  button {
    height: 44px;
    width: 94%;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    border: 1px solid gray;
    font-size: 18px;
    color: #3b3b3b;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button:hover {
    background-color: rgb(247, 220, 154);
  }
}

.description__content__container__dropDown__container__invalid {
  @extend .description__content__container__dropDown__container;

  button {
    background-color: rgb(253, 242, 242);
    border: 1px solid red !important;
  }
}

.description__content__container__dropDown__list {
  display: flex;
  width: 94% !important;
  height: 200px;
  overflow-y: scroll;
  scrollbar-width: thin;
  flex-direction: column;
  visibility: visible;
  position: absolute;
  z-index: 1;

  button {
    height: 44px !important;
    width: 100%;
    background-color: rgb(247, 247, 247);
    border-radius: 0px;
    border: none !important;
    border-bottom: 1px solid gray !important;
    border-right: 1px solid gray !important;
    border-left: 1px solid gray !important;
    font-size: 18px;
  }
}
