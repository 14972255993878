:global {
  #workbenchPageContent,
  .CanvasComponent.LCS .CanvasZone {
    max-width: 100% !important;
    min-height: 100%;
  }

  a {
    text-decoration: none;
  }
}

.appContainer {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: rgba(105, 105, 105, 0.103);
}

.logoLongContainer {
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  h2 {
    color: goldenrod;
    font-size: 20px;
    margin-left: -18px;
    margin-top: -1px;
  }
}

.searchAndSignContainer {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    background-color: rgb(248, 248, 248);
    border: 1px solid #bdc1c4;
    border-radius: 4px;
    width: 80%;
    height: 30px;
    outline: none;
    margin-left: 20px;
  }
}

.logoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  align-items: center;
  cursor: pointer;

  h1 {
    font-size: 13px;
    margin: 1px;
    color: #71787d;
  }

  img {
    position: relative;
    left: 62px;
  }
}

.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

h2 {
  font-size: 24px;
  text-align: center;
  margin: 0px;
}

.welcomeImage {
  max-width: 210px;
  height: 25px;
  margin: 0px 2px;
  display: flex;
}

.mainTitle {
  font-size: 48px;
  color: goldenrod;
}

.subTitle {
  font-size: 38px;
  color: #71787d;
}

.environmentMessage {
  color: darkgray;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

.adminButton {
  cursor: pointer;
  opacity: 0.8;
}

.adminButton:hover {
  opacity: 1;
}

.app__maintenance__container {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    color: goldenrod;
    font-size: 2em;
  }
}

.app__maintenance__windowBox {
  width: 80%;
  height: 60%;
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgb(73, 73, 73);

  p {
    width: 80%;
    text-align: center;
    color: #71787d;
    font-size: 1.1em;
    margin-bottom: 50px;
  }

  span {
    color: rgb(39, 179, 21);
    font-weight: bold;
  }
}

.app__maintenance__icon {
  width: 150px;
  height: 150px;
  animation: rotation 8s infinite linear;

  * {
    width: 150px;
    height: 150px;
    color: #71787d;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
}
