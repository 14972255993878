.stakeholders__customerContact__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    font-size: 1.8em;
    color: goldenrod;
    margin: 5px 0px 5px;
  }
}

.stakeholders__customerContact__container__section {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.stakeholders__add__contact__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.phoneInput {
  margin-left: 30px;
  width: 100%;
  margin-right: 0px !important;
}

.phoneInput__Container {
  display: flex;
  width: 100%;

  input {
    width: 100%;
  }
}

.phoneInput__prefix {
  width: 30px !important;
  margin-right: 10px !important;
}

.stakeholders__add__customer__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  background-color: #f7f7f7;
  border: 1px dashed #71787d;
  cursor: pointer;

  p {
    font-size: 2.5em;
    color: #71787d;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 200px;
  }
}

.stakeholders__add__customer__container:hover {
  opacity: 0.8;
}

.useCases__tableContainer {
  width: 100%;
  background-color: white;
  height: fit-content;
  border-radius: 8px;
  margin-bottom: 30px;
}

.useCases__tableContainer__header {
  display: flex;
  width: 100%;
  justify-content: left;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #71787d;
  border: 1px solid #71787d;

  div {
    width: 70%;
    margin: auto;
    border-left: 1px solid #71787d;

    p {
      color: white;
      font-size: 1.2em;
      font-weight: bold;
      margin-left: 10px;
    }
  }
}

.useCases__tableContainer__header__description {
  div {
    width: 60% !important;
    border-left: none !important;
  }
}

.useCases__tableContainer__row {
  display: flex;
  width: 100%;
  justify-content: left;
  border-bottom: 1px solid #999b9c;

  div {
    width: 100%;
    margin: 0px;

    p {
      color: #92979b;
      font-size: 1em;
      margin-left: 10px;
    }
  }
}

.conditionInputContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  div {
    width: 100%;
    margin-right: 30px;

    h5 {
      font-size: 1.3em;
      color: #71787d;
      margin: 3px 0px;
    }

    input {
      width: 100%;
      height: 32px;
      padding: 4px 10px;
      margin: 0px 0px 10px 0px;
      background-color: white;
      border: 1px solid #71787d;
      border-radius: 4px;
    }
  }
}

.emptyContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #71787d;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
  border-bottom: 1px dashed #71787d;
  border-right: 1px dashed #71787d;
  border-left: 1px dashed #71787d;
}

.table__buttonsContainer {
  position: absolute;
  right: -35px;

  button {
    color: rgb(189, 13, 13);
    background: none;
    border: 0px;
    font-size: 20px;
    padding: 0;
    margin: 0;
  }

  button:hover {
    opacity: 0.7;
  }
}

.customerPersons__tableContainer__row {
  display: flex;
  align-items: center;
  position: relative;
}
