.stageTitleContainer {
  width: 100%;

  color: #71787d;
  text-align: center;

  h3 {
    font-size: 3em;
    color: #71787d;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2em;
    width: 70%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }

  h5 {
    font-size: 1.3em;
    color: #71787d;
    margin: 3px 0px;
  }
}

.requestContentContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  height: auto;

  padding-bottom: 0px;
}

.inputContainer {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.inputColumn {
  width: 35%;
  h4 {
    font-size: 1.8em;
    color: goldenrod;
    margin: 5px 0px 5px;
  }

  h5 {
    font-size: 1.3em;
    color: #71787d;
    margin: 3px 0px;
  }

  input {
    width: 90%;
    height: 32px;
    padding: 4px 10px;
    margin: 0px 0px 10px;
    background-color: white;
  }
}

.icon {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  color: #71787d;

  cursor: pointer;
}

.requestTypeTilesSection {
  width: 650px;
  height: 420px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  div {
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 5px;
    border-radius: 8px;
    border: 2px solid #71787d;
    background-color: white;
    margin: 3px 5px 3px 5px;
    opacity: 0.8;

    cursor: pointer;

    h3 {
      text-align: center;
      cursor: pointer;
      color: #71787d;
    }

    h4 {
      color: #71787d;
      font-weight: bold;
      cursor: pointer;
    }

    p {
      max-width: 75%;
      font-size: 14px;
      text-align: center;
      padding-bottom: 20px;
      cursor: pointer;
    }

    input {
      display: none;
    }
  }

  div:hover {
    opacity: 1;
    background-color: rgb(250, 250, 250);
  }
}

.markedTile {
  opacity: 1 !important;
  background-color: rgb(250, 250, 250) !important;
  border: 2px solid goldenrod !important;
}

.errorMessage {
  color: rgb(194, 46, 46);
  font-size: 1.2em;
  text-align: center;
}

.requestNameContainer {
  margin-top: 30px;
  width: 33%;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  text-align: left;

  p {
    width: 100%;
    text-align: left;
  }
}

.requestNameInput {
  width: 100%;
  height: 32px;
  padding: 4px 10px;
  margin: 0px 0px 10px;
  background-color: white;
  border: 1px solid #71787d;
  border-radius: 4px;
}

.disabledTile {
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  border-radius: 8px;
  border: 2px solid #ececec !important;
  background-color: rgb(240, 240, 240) !important;
  margin: 3px 5px 3px 5px;
  opacity: 0.8;

  cursor: pointer;

  h3 {
    text-align: center;
    cursor: pointer;
    color: #71787d;
  }

  h4 {
    color: #71787d;
    font-weight: bold;
    cursor: pointer;
  }

  p {
    max-width: 75%;
    font-size: 14px;
    text-align: center;
    padding-bottom: 20px;
    cursor: pointer;
  }
}
.disabledTile:hover {
  opacity: 0.8 !important;
}

.fileUpload__container {
  width: 100%;
  margin: 0px;
  margin-top: 80px;
  margin-bottom: -50px;
  height: 30px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    color: goldenrod !important;
    margin: 0;
    font-weight: bold;
  }

  p {
    color: #71787d;
    font-size: 20px;
  }
}

.loadingContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 18px;

    color: #71787d;
  }

  span {
    width: 50px;
    height: 50px;
    animation: rotation 1s infinite linear;
    * {
      width: 50px;
      height: 50px;
      color: goldenrod;
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}

.loadingContainer__uploaded {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 18px;

    color: #71787d;
  }

  span {
    width: 50px;
    height: 50px;

    * {
      width: 50px;
      height: 50px;
      color: goldenrod;
    }
  }
}
