.stageTitleContainer {
  width: 100%;
  color: #71787d;
  text-align: center;

  h3 {
    font-size: 3em;
    color: #71787d;
  }

  h4 {
    font-size: 1.7em;
    color: goldenrod;
    margin: 0px;
    position: relative;
    top: -40px;
  }

  h5 {
    font-size: 2.5em;
    margin: 0px;
    position: relative;
    top: -20px;
    color: #71787d;
  }

  p {
    font-size: 1.2em;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
}

.requestStageIcon {
  color: #71787d;
  position: relative;
  top: -5px;
  margin: 35px;
  width: 100px;
  height: 100px;
}

.requestContentContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.inputContainer {
  display: block;
  width: 80%;
}

.inputColumn {
  width: 100%;

  h4 {
    font-size: 1.8em;
    color: goldenrod;
    margin: 5px 0px 5px;
  }

  h5 {
    font-size: 1.3em;
    color: #71787d;
    margin: 3px 0px;
  }

  p {
    width: 100%;
    color: #71787d;
  }

  textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: fit-content;
    min-height: 200px;
    padding: 4px 10px;
    margin: 0px 0px 10px;
    background-color: white;
    border: 1px solid #71787d;
    border-radius: 4px;

    font-size: 1em;
  }

  input {
    width: 90%;
    height: 32px;
    padding: 4px 10px;
    margin: 0px 0px 10px;
    background-color: white;
    border: 1px solid #71787d;
    border-radius: 4px;
  }
}

.context__featureInput__container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.tsi__venue__venueBusiness__checkboxesContainer {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;

  vertical-align: baseline;

  div {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      margin-top: 20px;

      input {
        height: 18px;
        width: 18px;
        margin: 0px 10px 0px 0;
      }

      p {
        margin: 0;
      }
    }
  }
}

.tsi__venue__venueBusiness__ownInput__container {
  display: flex;
}

.tsi__venue__venueBusiness__ownInput {
  width: 30% !important;
  margin-bottom: 30px;
}

.tsi__venue__venueBusiness__button {
  background-color: goldenrod;
  color: white;
  border: none;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  font-size: 30px;
  margin-left: 5px;

  * {
    margin-top: 3px;
  }
}

.tsi__venue__venueBusiness__button:hover {
  opacity: 0.8;
}

.tsi__venue__expectedVisitors__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  input {
    width: 50%;
  }

  h6 {
    font-size: 1em;
    color: #71787d;
    margin: 0 0 10px 0;
  }
}

.dropzone {
  height: 160px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 4px;
  border-color: #71787d;
  border-style: dashed;
  background-color: transparent;
  color: #71787d;
  outline: none;
  font-weight: bold;
  cursor: pointer;

  position: relative;
  left: 50%;
  transform: translate(-50%);

  &:hover,
  &::after {
    border-width: 2px;
    border-style: dashed;
    background-color: white;
    opacity: 0.7;
  }

  p {
    text-align: center;
    cursor: pointer;
  }
}

.addedFillesText {
  font-weight: bold;
  color: #71787d;
}

.dropBoxIconWrapper {
  height: 60px;
  width: 60px;
  color: #71787d;

  * {
    width: 100%;
    height: 100%;
  }
}

.tsi__venue__checkpoints__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  input {
    width: 50%;
  }

  h6 {
    font-size: 1em;
    color: #71787d;
    margin: 0 0 10px 0;
  }
}

.errorMessage {
  color: rgb(194, 46, 46);
  font-size: 1.2em;
  text-align: center;
}
