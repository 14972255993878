.backToMenuButton {
  width: 160px;
  height: 40px;
  border: none;
  background-color: transparent;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  opacity: 1;
  color: #61676b;
  font-size: 1em;
  cursor: pointer;

  * {
    margin: 3px;
  }
}

.backToMenuButton:hover {
  opacity: 0.7;
}
