.buttonDownload {
  margin: 20px 0;
  padding: 0.5em 1em;

  font-size: 14px;
  border-radius: 4px;
  border: none;
  opacity: 0.7;
  background-color: #71787d;
  border: 1px solid #71787d;
  border-radius: 8px;
  color: white;
}

.buttonDownload:hover {
  opacity: 1;
}
