.stageTitleContainer {
  width: 100%;
  color: #71787d;
  text-align: center;

  h3 {
    font-size: 3em;
    color: #71787d;
  }

  h4 {
    font-size: 1.7em;
    color: goldenrod;
    margin: 0px;
    position: relative;
    top: -40px;
  }

  h5 {
    font-size: 2.5em;
    margin: 0px;
    position: relative;
    top: -20px;
    color: #71787d;
  }

  p {
    font-size: 1.2em;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
}

.requestStageIcon {
  color: #71787d;
  position: relative;
  top: -5px;
  margin: 35px;
  width: 100px;
  height: 100px;
}

.requestContentContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.inputContainer {
  display: block;
  width: 80%;
}

.inputColumn {
  width: 100%;

  h4 {
    font-size: 1.8em;
    color: goldenrod;
    margin: 5px 0px 5px;
  }

  h5 {
    font-size: 1.3em;
    color: #71787d;
    margin: 3px 0px;
  }

  p {
    width: 100%;
    color: #71787d;
  }

  textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: fit-content;
    min-height: 200px;
    margin: 0px 0px 10px;
    background-color: white;
    border: 1px solid #71787d;
    border-radius: 4px;

    font-size: 1em;
  }

  ul {
    list-style: none;
  }
}

.context__featureInput__container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.dropzone {
  height: 160px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 4px;
  border-color: #71787d;
  border-style: dashed;
  background-color: transparent;
  color: #71787d;
  outline: none;
  font-weight: bold;
  cursor: pointer;

  position: relative;
  left: 50%;
  transform: translate(-50%);

  &:hover,
  &::after {
    border-width: 2px;
    border-style: dashed;
    background-color: white;
    opacity: 0.7;
  }

  p {
    text-align: center;
    cursor: pointer;
  }
}

.addedFillesText {
  font-weight: bold;
  color: #71787d;
}

.dropBoxIconWrapper {
  height: 60px;
  width: 60px;
  color: #71787d;

  * {
    width: 100%;
    height: 100%;
  }
}
