.stageTitleContainer {
  width: 100%;
  color: #71787d;
  text-align: center;

  h3 {
    font-size: 3em;
    color: #71787d;
  }

  h4 {
    font-size: 1.7em;
    color: goldenrod;
    margin: 0px;
    position: relative;
    top: -40px;
  }

  h5 {
    font-size: 2.5em;
    margin: 0px;
    position: relative;
    top: -20px;
    color: #71787d;
  }

  p {
    font-size: 1.2em;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
}

.requestStageIcon {
  color: #71787d;
  position: relative;
  top: -5px;
  margin: 35px 0;
  width: 100px;
  height: 100px;
}

.requestContentContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.inputContainer {
  display: block;
  width: 80%;
}

.inputColumn {
  width: 100%;

  h4 {
    font-size: 1.8em;
    color: goldenrod;
    margin: 5px 0px 5px;
  }

  h5 {
    font-size: 1.3em;
    color: #71787d;
    margin: 3px 0px;
  }

  p {
    width: 100%;
    color: #71787d;
  }

  textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: fit-content;
    min-height: 200px;
    padding: 4px 10px;
    margin: 0px 0px 10px;
    background-color: white;
    border: 1px solid #71787d;
    border-radius: 4px;

    font-size: 1em;
  }
}

.context__featureInput__container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button {
      width: 30%;
      padding: 1.3em 0.8em;
      font-size: 1.3em;
      background-color: #71787d;
      color: white;
      border: 2px solid rgb(250, 250, 250);
      border-radius: 8px;
      opacity: 1;
    }

    button:hover {
      background-color: rgb(218, 187, 108);
    }
  }
}

.timeline__selectedButton {
  background-color: goldenrod !important;
}

.calendarContainer {
  width: 100%;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;

  span {
    color: #71787d !important;
  }
}

.description__text {
  color: #71787d;
  margin-bottom: 60px;
  text-align: center;
}

.errorMessage {
  color: rgb(194, 46, 46);
  font-size: 1.2em;
  text-align: center;
}

.timeline__dateValues__container {
  div {
    margin: 10px 3px;
    border: 1px solid #71787d;
    border-radius: 4px;
    background-color: white;
    position: relative;

    span {
      position: absolute;
      top: 22px;
      right: 10px;
      cursor: pointer;

      * {
        width: 25px;
        height: 25px;
      }
    }
    p {
      font-size: 1.3em !important;
      color: #71787d;
    }
  }

  .timeline__date__container__active {
    background-color: rgb(243, 243, 243) !important;
    position: relative;
  }
}
.timeline__date__container__error {
  background-color: rgb(247, 200, 200) !important;
  p {
    color: rgb(117, 11, 11) !important;
  }
}
