.contactList__mainContainer {
  width: 90%;
  height: fit-content;
  min-height: 80vh;

  h2 {
    color: goldenrod;
    font-size: 28px;
  }
}

.contactList__buttonContainer {
  margin-top: 20px;

  button {
    width: 150px;
    height: 40px;
    border: none;
    background-color: transparent;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    opacity: 1;
    color: #61676b;
    font-size: 1em;
    cursor: pointer;

    * {
      margin: 3px;
    }
  }

  button:hover {
    opacity: 0.7;
  }
}
