.stageTitleContainer {
  width: 100%;
  color: #71787d;
  text-align: center;

  h3 {
    font-size: 3em;
    color: #71787d;
  }

  h4 {
    font-size: 1.7em;
    color: goldenrod;
    margin: 0px;
    position: relative;
  }

  h5 {
    font-size: 2.5em;
    margin: 20px;
    position: relative;

    color: #71787d;
  }

  p {
    font-size: 1.2em;
    width: 70%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
}

.requestStageIcon {
  color: #71787d;
  position: relative;
  top: -5px;
  margin: 35px;
  width: 100px;
  height: 100px;
}

.requestContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  min-height: 50vh;
}

.inputContainer {
  display: block;
  width: 80%;
}

.inputColumn {
  width: 100%;

  h4 {
    font-size: 1.8em;
    color: goldenrod;
    margin: 5px 0px 5px;
  }

  h5 {
    font-size: 1.3em;
    color: #71787d;
    margin: 3px 0px;
  }

  p {
    width: 100%;
    color: #71787d;
  }

  textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: fit-content;
    min-height: 200px;
    margin: 0px 0px 10px;
    background-color: white;
    border: 1px solid #71787d;
    border-radius: 4px;

    font-size: 1em;
  }

  ul {
    list-style: none;
  }
}

.context__featureInput__container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.dropzone {
  height: 160px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 4px;
  border-color: #71787d;
  border-style: dashed;
  background-color: transparent;
  color: #71787d;
  outline: none;
  font-weight: bold;
  cursor: pointer;

  position: relative;
  left: 50%;
  transform: translate(-50%);

  &:hover,
  &::after {
    border-width: 2px;
    border-style: dashed;
    background-color: white;
    opacity: 0.7;
  }

  p {
    text-align: center;
    cursor: pointer;
  }
}

.addedFillesText {
  font-weight: bold;
  color: #71787d;
}

.dropBoxIconWrapper {
  height: 60px;
  width: 60px;
  color: #71787d;

  * {
    width: 100%;
    height: 100%;
  }
}

.summary_container {
  position: absolute;
}

.stageholdersContainer {
  width: 90%;
  display: flex;
  height: fit-content;

  justify-content: center;
  align-items: left;
  margin: 5px 0;
}

.stageholdersContainer__window {
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #d8d8d8;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: left;
  align-items: self-start;
  padding: 5px 0px 30px;

  h3 {
    font-size: 24px;
    margin: 15px;
    color: #71787d;
    min-width: 300px;
  }

  h4 {
    color: goldenrod;
    font-size: 18px;
    margin-bottom: 0px;
  }

  p {
    max-width: 80%;
    color: #71787d;
  }
}

.sectionContainer__sideBar {
  position: relative;
  left: 0;
  width: 30px;
  background-color: #dfdfdf;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.stageholdersContainer__columns {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.stageholdersContainer__customerContactPerson {
  width: 97%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;

  h4 {
    margin-bottom: 20px;
  }
}

.stageholdersContainer__customerContactPerson__Row {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row !important;
  justify-content: left;

  div {
    width: 100%;
    border-bottom: 1px solid #d3d3d3;
  }

  p {
    margin-left: 10px;
    color: #71787d;
  }
}

.useCasesSummary__tableContainer {
  width: 100%;
  border-radius: 4px;
}

.useCasesSummary__tableHeader {
  width: 100% !important;
  display: flex;
  flex-direction: row !important;
  justify-content: left;
  background-color: #fafafa;
  border-bottom: 2px solid #c6c8c9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  div {
    width: 100%;
    height: 32px;

    display: flex;
    justify-content: left;
    align-items: center !important;

    p {
      margin: 0;
      margin-left: 10px;
      font-weight: bold;
      color: #71787d;
    }
  }
}

.emptyTable {
  display: flex;
  justify-content: center;
  align-items: center;
}

.useCasesSummary__requesterSection {
  width: 97%;
  margin-bottom: 40px;

  h4 {
    text-align: left;
    margin-bottom: 20px;
  }
}

.useCasesSummary__customerSection {
  width: 97%;
  margin-bottom: 40px;

  h4 {
    text-align: left;
    margin-bottom: 20px;
  }
}

.requestMainCotnainer {
  width: 90%;
}

.requestContainer {
  width: 100%;
  height: fit-content;
  border: 1px solid #71787d;
  border-radius: 8px;
  padding-bottom: 40px;
  background: rgb(250, 250, 250);
}

.requestHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 60px;
  background: #71787d;

  h2 {
    font-size: 24px;
    text-align: center;
    color: white;
  }
}

.contactList__buttonContainer {
  margin-top: 20px;

  button {
    width: 150px;
    height: 40px;
    border: none;
    background-color: transparent;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    opacity: 1;
    color: #61676b;
    font-size: 1em;
    cursor: pointer;

    * {
      margin: 3px;
    }
  }

  button:hover {
    opacity: 0.7;
  }
}

.stageholdersContainer__window__contentContainer {
  display: block;
  width: 100%;
  border-left: 1px solid #e2e2e2;
  padding: 0px 20px 20px 20px;
}

.stageTitleContainer {
  display: flex;
  width: 100%;
  justify-content: left;

  div {
    width: 50%;
    text-align: left;

    p {
      transform: none;
      position: inherit;
      font-size: 2em;
      font-weight: bold;
      padding: 0 !important;
      text-align: left;
      margin: 0px !important;
    }
  }
}
