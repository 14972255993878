.stageTitleContainer {
  width: 100%;
  color: #71787d;
  text-align: center;

  h3 {
    font-size: 3em;
    color: #71787d;
  }

  h4 {
    font-size: 1.7em;
    color: goldenrod;
    margin: 0px;
    position: relative;
    top: -40px;
  }

  h5 {
    font-size: 2.5em;
    margin: 0px;
    position: relative;
    top: -20px;
    color: #71787d;
  }

  p {
    font-size: 1.2em;
    width: 70%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
}

.requestStageIcon {
  color: #71787d;
  position: relative;
  top: -5px;
  margin: 35px;
  width: 100px;
  height: 100px;
}

.requestContentContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
}

.useCases__tableContainer {
  width: 80%;
  background-color: white;
  height: fit-content;
  border-radius: 8px;

  margin-bottom: 30px;
}

.useCases__tableContainer__header {
  display: flex;
  width: 100%;
  justify-content: left;
  background-color: #71787d;
  border-bottom: 2px solid #71787d;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  div {
    width: 100%;
    margin: auto;
    border-left: 1px solid #71787d;

    p {
      color: white;
      font-size: 1.2em;
      font-weight: bold;
      margin-left: 10px;
    }
  }
}

.useCases__tableContainer__header__description {
  div {
    width: 60% !important;
    border-left: none !important;
  }
}

.useCases__tableContainer__row {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: left;
  border-bottom: 1px solid #999b9c;

  div {
    width: 100%;
    margin: 0px;

    p {
      color: #92979b;
      font-size: 1em;
      margin-left: 10px;
      max-width: 90%;
      word-wrap: break-word;
    }
  }
}

.inputContainer {
  width: 80%;

  h4 {
    font-size: 1.8em;
    color: goldenrod;
    margin: 5px 0px 5px;
  }
}

.descriptionInputContainer {
  width: 100%;

  h5 {
    font-size: 1.3em;
    color: #71787d;
    margin: 3px 0px;
  }

  textarea {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 84px;
    padding: 4px 10px;
    margin: 0px 0px 10px;
    background-color: white;
    border: 1px solid #71787d;
    border-radius: 4px;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
  }
}
.conditionInputContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  div {
    width: 48%;

    h5 {
      font-size: 1.3em;
      color: #71787d;
      margin: 3px 0px;
    }

    textarea {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      min-height: 84px;
      padding: 4px 10px;
      margin: 0px 0px 10px;
      background-color: white;
      border: 1px solid #71787d;
      border-radius: 4px;
      font-size: 18px;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
}

.emptyContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #71787d;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
  border-bottom: 1px dashed #71787d;
  border-right: 1px dashed #71787d;
  border-left: 1px dashed #71787d;
}

.table__buttonsContainer {
  position: absolute;
  right: -35px;

  button {
    color: rgb(189, 13, 13);
    background: none;
    border: 0px;
    font-size: 20px;
    padding: 0;
    margin: 0;
  }

  button:hover {
    opacity: 0.7;
  }
}

.useCases__tableContainer__rowWithButtons {
  display: flex;
  align-items: center;
  position: relative;
}

.addButtonContainer {
  display: flex;
  justify-content: center;
}
