.requestMainCotnainer {
  width: 90%;
}

.requestContainer {
  width: 100%;
  height: fit-content;
  border: 2px solid #71787d;
  border-radius: 8px;
  padding-bottom: 40px;
  background: rgb(250, 250, 250);
}

.requestHeader {
  display: flex;
  align-items: left;
  justify-content: left;
  width: 100%;
  min-height: 200px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 60px;
  background: #71787d1e;

  h2 {
    font-size: 64px;
    text-align: center;
    color: rgb(255, 255, 255);
    z-index: 2;
  }
}

.requestHeader__container {
  position: absolute;
  height: 200px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.requestHeader__image {
  width: 100%;
  position: absolute;
  top: 0;

  div {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.493);
    z-index: 1;
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    margin-bottom: -4px;
  }
}
