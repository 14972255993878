.popOut__container {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.466);

  z-index: 3;
}

.popOut__window {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 60%;
  height: fit-content;
  padding: 30px;
  background-color: white;
  border: 2px solid #71787d;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  z-index: 2;
}

.popOut__header {
  color: #71787d;
  font-size: 3em;
  margin: 10px;
}

.popOut__text {
  width: 90%;
  font-size: 1.2em;
}

.popOut__buttons__container {
  display: flex;
  justify-content: space-around;
}

.popOut__button {
  height: 32px;
  width: fit-content;
  padding: 1em 2em;
  font-size: 1.3em;
  border: none;
  border-radius: 4px;
  margin: 5px;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  opacity: 1;
  background-color: goldenrod;
  color: white;

  * {
    margin-right: 10px;
  }
}
.popOut__button:hover {
  opacity: 0.8;
}

.popOut__yes__button {
  height: 32px;
  width: fit-content;
  padding: 1em 2em;
  font-size: 1.3em;
  border: none;
  border-radius: 4px;
  margin: 5px;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  opacity: 1;
  background-color: goldenrod;
  color: white;

  * {
    margin-right: 10px;
  }
}
.popOut__yes__button:hover {
  opacity: 0.8;
}

.popOut__no__button {
  height: 32px;
  width: fit-content;
  padding: 1em 2em;
  font-size: 1.3em;
  border: none;
  border-radius: 4px;
  margin: 5px;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  opacity: 1;
  background-color: rgb(199, 99, 99);
  color: white;

  * {
    margin-right: 10px;
  }
}
.popOut__no__button:hover {
  opacity: 0.8;
}

.popOut__cancel__button {
  height: 32px;
  width: 140px;
  padding: 1em 2em;
  font-size: 1.3em;
  border: none;
  border-radius: 4px;
  margin: 5px;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  opacity: 1;
  background-color: #b6b8b9;
  color: white;

  * {
    margin-right: 10px;
  }
}
.popOut__cancel__button:hover {
  opacity: 0.8;
}

.popOut__downloadContainer {
  width: 150px;
  height: 140px;
  margin: 40px;
  border: 2px dashed #71787d;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    color: #71787d !important;
    margin: 0 !important;
    * {
      width: 70px;
      height: 70px;
    }
  }

  p {
    font-weight: bold;
    color: #71787d;
  }

  cursor: pointer;
}

.popOut__downloadContainer:hover {
  background-color: #f7f6f6;
}

.popOut__loadingIcon {
  width: 100px;
  height: 100px;
  animation: rotation 1s infinite linear;

  * {
    width: 100px;
    height: 100px;
    color: goldenrod;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.popOut__ticketingAccountWindow {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 60%;
  height: fit-content;
  background-color: white;
  border: 2px solid #71787d;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  z-index: 2;
}

.popOut__ticketingAccountHeader {
  width: 100%;
  height: 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #71787d;

  h4 {
    font-size: 1.5em;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.popOut__ticketingAccountContent {
  height: fit-content;
  padding: 40px 0;
  width: 100%;
  background-color: #f7f6f6;

  h5 {
    color: goldenrod;
    font-size: 1.2em;
    margin: 10px 0;
  }
}

.popOut__closeButton__Wrapper {
  color: rgb(241, 241, 241);

  opacity: 0.8;
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;

  * {
    width: 30px;
    height: 30px;
  }
}

.popOut__closeButton__Wrapper:hover {
  opacity: 1;
}

.popOut__ticketingAccountContent__columns {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #71787d;
  padding-bottom: 40px;
}

.popOut__ticketingAccountContent__column {
  text-align: left;
  min-width: 200px;
  div {
    min-height: 60px;
    text-align: left;
    margin-bottom: 20px;

    p {
      color: #71787d;
    }
  }
}

.popOut__ticketingAccountContent__columns__checkbox {
  display: flex;
  width: 100%;
  justify-content: space-around;

  div {
    width: 30%;
  }
}
