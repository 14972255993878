.homeMainContentContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.homeFirstRowContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.homeAccountContainer {
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin-top: 60px;

  div {
    width: 20%;

    h3 {
      color: #71787d;
    }

    p {
      color: #71787d;
      font-size: 12px;
    }
  }
}

.homeNotificationsContainer {
  width: 600px;
  display: block;
  justify-content: left;
  margin-top: 60px;
  margin-left: 60px;

  h3 {
    color: #71787d;
  }

  div {
    input {
      accent-color: rgb(243, 210, 126);
    }
    label {
      color: #71787d;
      margin-left: 10px;
    }
  }
}

.homeContentContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 60px 0px;
  width: 100%;
}

.currentRequestContainer {
  width: 60%;
}
