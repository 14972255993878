.searchBar__container {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  position: relative;

  input {
    position: relative;
    background-color: rgb(255, 255, 255);
    border: 1px solid #bdc1c4;
    border-radius: 4px;
    width: 100% !important;
    left: 6px;

    height: 30px;
    outline: none;
    padding: 0 0 0 10px !important;
    margin: 0 !important;
  }
}

.searchBar__searchRowsContainer {
  width: 100% !important;
  max-height: 320px;
  height: 320px;
  overflow: hidden;
  all: unset;
  position: absolute;
  top: 30px;

  div {
    display: flex;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border: 1px solid #bdc1c4;
    width: 100%;
    height: 30px;
    outline: none;
    padding: 0 0 0 10px !important;
    text-align: left;
    margin: 0 !important;
    color: #71787d;
    cursor: pointer;
    position: relative;
  }

  div:hover {
    background-color: #f5f5f5;
  }
}
