.stageTitleContainer {
  width: 100%;
  color: #71787d;
  text-align: center;

  h3 {
    font-size: 3em;
    color: #71787d;
  }

  h4 {
    font-size: 1.7em;
    color: goldenrod;
    margin: 0px;
    position: relative;
    top: -40px;
  }

  h5 {
    font-size: 2.5em;
    margin: 0px;
    position: relative;
    top: -20px;
    color: #71787d;
  }

  p {
    font-size: 1.2em;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
}

.requestStageIcon {
  color: #71787d;
  position: relative;
  top: -5px;
  margin: 35px;
  width: 100px;
  height: 100px;
}

.requestContentContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.inputContainer {
  display: block;
  width: 80%;
}

.inputColumn {
  width: 100%;
  margin: 60px 0;

  h4 {
    font-size: 1.8em;
    color: goldenrod;
    margin: 5px 0px 5px;
  }

  h5 {
    font-size: 1.3em;
    color: #71787d;
    margin: 3px 0px;
  }

  p {
    width: 100%;
    color: #71787d;
  }

  textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: fit-content;
    min-height: 200px;
    padding: 4px 10px;
    margin: 0px 0px 10px;
    background-color: white;
    border: 1px solid #71787d;
    border-radius: 4px;

    font-size: 1em;
  }

  input {
    width: 90%;
    height: 32px;
    padding: 4px 10px;
    margin: 0px 0px 10px;
    background-color: white;
    border: 1px solid #71787d;
    border-radius: 4px;
  }
}

.context__featureInput__container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.dropzone {
  height: 160px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 4px;
  border-color: #71787d;
  border-style: dashed;
  background-color: transparent;
  color: #71787d;
  outline: none;
  font-weight: bold;
  cursor: pointer;

  position: relative;
  left: 50%;
  transform: translate(-50%);

  &:hover,
  &::after {
    border-width: 2px;
    border-style: dashed;
    background-color: white;
    opacity: 0.7;
  }

  p {
    text-align: center;
    cursor: pointer;
  }
}

.addedFillesText {
  font-weight: bold;
  color: #71787d;
}

.dropBoxIconWrapper {
  height: 60px;
  width: 60px;
  color: #71787d;

  * {
    width: 100%;
    height: 100%;
  }
}

.tsi__ticketAndDataCarriers__barcode__container {
  width: 100%;
  border: 2px solid #71787d;
  border-radius: 4px;
  background-color: #f5f5f5;
}

.tsi__ticketAndDataCarriers__barcode__inputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tsi__ticketAndDataCarriers__barcode__column {
  width: 30%;
  padding: 40px;
}

.tsi__ticketAndDataCarriers__barcode__column__right {
  padding: 40px;

  div {
    margin: 0;
    margin-bottom: 10px;

    input {
      width: 100%;
      height: 32px;
      padding: 4px 10px;
      margin: 0px 0px 10px;
      background-color: white;
      border: 1px solid #71787d;
      border-radius: 4px;
    }

    input {
      width: 97%;
    }
  }
  width: 40%;
}

.tsi__ticketAndDataCarriers__barcode__barcodeType__container {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
}

.tsi__ticketAndDataCarriers__barcode__barcodeType__column {
  width: 20%;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    margin-top: 20px;

    input {
      height: 18px;
      width: 18px;
      margin: 0px 10px 0px 0;
    }

    p {
      margin: 0;
    }
  }
}

.tsi__ticketAndDataCarriers__rfid__container {
  width: 100%;
  border: 2px solid #71787d;
  border-radius: 4px;
  background-color: #f5f5f5;
}

.tsi__ticketAndDataCarriers__rfid__inputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tsi__ticketAndDataCarriers__rfid__column {
  width: 50%;
  padding: 40px;
}

.tsi__ticketAndDataCarriers__rfid__column__right {
  padding: 80px 40px;

  div {
    margin: 0;

    input {
      width: 100%;
    }
  }
  width: 40%;
}

.tsi__ticketAndDataCarriers__rfid__rfidType__container {
  display: flex;
  justify-content: left;
  margin: 10px 0;
  width: 100%;
}

.tsi__ticketAndDataCarriers__rfid__rfidType__column {
  width: 100%;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    input {
      height: 18px;
      width: 18px;
      margin: 0px 10px 0px 0;
    }

    p {
      margin: 0;
    }
  }
}

.tsi__ticketAndDataCarriers__rfid__column__header {
  display: flex;
  width: 100%;

  justify-content: space-between;

  h5 {
    width: 100%;
  }
}

.tsi__ticketAndDataCarriers__AddButton {
  height: 32px;
  padding: 1em 1.2em;
  font-size: 1.3em;
  border: none;
  border-radius: 4px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: goldenrod;
  color: white;
  opacity: 1;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tsi__ticketAndDataCarriers__AddButton:hover {
  opacity: 0.8;
}

.tsi__ticketAndDataCarriers__AddButton__icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.tsi__barcode__tableContainer {
  width: 100%;
  background-color: white;
  height: fit-content;
  border-radius: 8px;
  margin-bottom: 60px;
}

.tsi__barcode__tableContainer__header {
  display: flex;

  width: 100%;
  justify-content: left;
  background-color: #71787d;
  border-bottom: 2px solid #71787d;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  div {
    width: 100%;
    margin: auto;

    p {
      color: white;
      font-size: 1em;
      font-weight: bold;
      margin: 8px;
      margin-left: 10px;
    }
  }
}

.tsi__barcode__tableContainer__header__description {
  div {
    width: 60% !important;
    border-left: none !important;
  }
}

.tsi__barcode__tableContainer__row {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: left;
  border-bottom: 1px solid #999b9c;

  div {
    width: 100%;
    margin: 0px;

    p {
      color: #92979b;
      font-size: 1em;
      margin-left: 10px;
      max-width: 90%;
      word-wrap: break-word;
    }
  }
}

.tsi__barcode__table__buttonsContainer {
  button {
    position: relative;
    left: 100%;
    color: rgb(189, 13, 13);
    background: none;
    border: 0px;
    font-size: 20px;
    padding: 0;
    margin: 0;
  }

  button:hover {
    opacity: 0.7;
  }
}

.tsi__barcode__tableContainer__row__valueWithButtonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tsi__barcode__emptyContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #71787d;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
  border-bottom: 1px dashed #71787d;
  border-right: 1px dashed #71787d;
  border-left: 1px dashed #71787d;

  p {
    text-align: center;
  }
}

.array__object__validationMessage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  p {
    top: -60px;
    position: absolute;
    color: rgb(216, 31, 31) !important;
    text-align: center;
  }
}

.array__object__validationMessage__Barcode {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  p {
    top: -20px;
    position: absolute;
    color: rgb(216, 31, 31) !important;
    text-align: center;
  }
}

.input__validationNotSuccesfull {
  border-radius: 0px !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border: 1px solid #ff3535 !important;
}

.input__validationBox {
  min-height: 25px;
  border-bottom: 1px solid #ff3535;
  border-left: 1px solid #ff3535;
  border-right: 1px solid #ff3535;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 97% !important;
  padding: 4px 10px;
  margin-top: -10px !important;
  background-color: #ffc5c5fd;

  p {
    margin: 2px 0;
    color: #883636 !important;
  }
}
