span {
  color: rgb(211, 57, 57);
  margin-left: 10px;
}

.stageTitleContainer {
  width: 100%;
  color: #71787d;
  text-align: center;

  h3 {
    font-size: 3em;
    color: #71787d;
  }

  h4 {
    font-size: 1.7em;
    color: goldenrod;
    margin: 0px;
  }

  h5 {
    font-size: 2.5em;
    margin: 0px;
    position: relative;
    top: -20px;
    color: #71787d;
  }

  p {
    font-size: 1.2em;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
}

.requestStageIcon {
  color: #71787d;
  position: relative;
  top: -5px;
  margin: 35px 0;

  width: 100px;
  height: 100px;
}

.requestContentContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  h4 {
    font-size: 1.7em;
    color: goldenrod;
    margin: 40px 0 0 0;
  }
}

.inputContainer {
  display: block;
  width: 80%;
}

.inputColumn {
  padding-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #71787d;
  padding-bottom: 70px;
  position: relative;

  div {
    width: 45%;
  }

  h4 {
    font-size: 1.8em;
    color: goldenrod;
    margin: 5px 0px 5px;
  }

  h5 {
    font-size: 1.3em;
    color: #71787d;
    margin: 3px 0px;
  }

  p {
    width: 100%;
    color: #71787d;
  }

  textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: fit-content;
    min-height: 200px;
    padding: 4px 10px;
    margin: 0px 0px 10px;
    background-color: white;
    border: 1px solid #71787d;
    border-radius: 4px;

    font-size: 1em;
  }

  input {
    width: 90%;
    height: 32px;
    padding: 4px 10px;
    margin: 0px 0px 10px;
    background-color: white;
    border: 1px solid #71787d;
    border-radius: 4px;
  }
}

.context__featureInput__container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.errorMessage {
  color: rgb(194, 46, 46) !important;
  font-size: 1.2em !important;
  text-align: center;
}

.nameErrorMessage {
  color: rgb(194, 46, 46) !important;
  font-size: 1.2em !important;
  text-align: left;
}

.tsi__stakeholders__strategicCheckbox__container {
  margin-top: 135px;
  div {
    width: 100%;
    display: flex;

    justify-content: left;
    align-items: center;

    h6 {
      margin: 0 25px;
      font-size: 24px;
      color: #71787d;
    }
  }
}

.toggle__wrapper {
  scale: 1.2;
  margin-top: 4px;
}

.toggle__text {
  color: white !important;
}

.inputColumn__requester__container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding-bottom: 70px;
  z-index: 1;

  border-bottom: 1px solid #b0b2b3;

  position: relative;
  left: 50%;
  transform: translate(-50%);

  h4 {
    font-size: 1.8em;
    color: goldenrod;
    margin: 5px 0px 5px;
  }

  h5 {
    font-size: 1.3em;
    color: #71787d;
    margin: 3px 0px;
  }

  p {
    width: 100%;
    color: #71787d;
  }

  textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: fit-content;
    min-height: 200px;
    padding: 4px 10px;
    margin: 0px 0px 10px;
    background-color: white;
    border: 1px solid #71787d;
    border-radius: 4px;

    font-size: 1em;
  }

  input {
    width: 91%;
    height: 32px;
    padding: 4px 10px;
    margin: 0px 0px 10px;
    background-color: white;
    border: 1px solid #71787d;
    border-radius: 4px;
  }
}

.inputColumn__requester__columns__container {
  display: flex;
  justify-content: space-between;
}

.inputColumn__requester__column {
  width: 45%;
}

.inputBox__Container {
  width: 91% !important;

  input {
    width: 100%;
    height: 32px;
    padding: 4px 10px;
    margin: 0px 0px 10px;
    background-color: white;
    border: 1px solid #71787d;
    border-radius: 4px;
  }
}

.input__validationNotSuccesfull {
  border-radius: 0px !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border: 1px solid #ff3535 !important;
}

.input__validationBox {
  min-height: 25px;
  border-bottom: 1px solid #ff3535;
  border-left: 1px solid #ff3535;
  border-right: 1px solid #ff3535;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100% !important;
  padding: 4px 10px;
  margin-top: -10px;
  background-color: #ffc5c5fd;

  p {
    margin: 2px 0;
    color: #883636 !important;
  }
}
