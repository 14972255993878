.sectionContainer {
  width: 90%;
  display: flex;
  height: fit-content;

  justify-content: center;
  align-items: left;
  margin: 5px 0;
}

.sectionContainer__window {
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #d8d8d8;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: left;
  align-items: self-start;
  padding: 5px 0px 30px;

  h3 {
    font-size: 24px;
    margin: 15px;
    color: #71787d;
    min-width: 300px;
  }

  h4 {
    color: goldenrod;
    font-size: 18px;
    margin-bottom: 0px;
  }

  p {
    max-width: 80%;
    color: #71787d;
  }
}

.sectionContainer__sideBar {
  position: relative;
  left: 0;
  width: 30px;
  background-color: #dfdfdf;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.sectionContainer__columns {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.sectionContainer__window__contentContainer {
  border-left: 1px solid #e2e2e2;
  padding-left: 30px;
  min-height: 300px;
  width: 100%;
}

.sectionContainer__customerContactPerson {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 90%;
  }
}

.sectionContainer__customerContactPerson__Row {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #b6b6b6;
}

.useCasesSummary__tableContainer {
  width: 80%;

  border-radius: 4px;
}

.useCasesSummary__tableHeader {
  display: flex;
  justify-content: left;
  background-color: #71787d;
  border-radius: 8px;

  div {
    width: 100%;
    height: 32px;

    display: flex;
    align-items: center;

    p {
      margin: 0;
      margin-left: 10px;
      font-weight: bold;
      color: white;
    }
  }
}

.useCasesSummary__tableRow {
  display: flex;
  justify-content: left;
  border-radius: 4px;
  div {
    width: 100%;
    min-height: 32px;
    border-bottom: 1px solid #d3d3d3;

    p {
      padding-right: 50px;
      padding-left: 10px;
    }
  }
}

.emptyTable {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tsi__venue__venuBussines__radioContainer {
  display: flex;
  width: 80%;
  justify-content: space-between;

  div {
    display: flex;

    input {
      margin-right: 10px;
    }
  }
}

.tsi__venue__typeOfEvents__radioContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: left;

  div {
    width: 200px;
    display: flex;
    align-items: center;

    input {
      margin-right: 10px;
    }
  }
}

.tsi__venue__expectedVisitors__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 10px;
  }
  input {
    width: 100px;
    background-color: transparent;
    border: none;

    text-align: center;
  }

  h6 {
    font-size: 1em;
    color: #71787d;
    margin: 0 0 10px 0;
  }
}

.tsi__venue__checkpoints__container {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: space-between;
  margin-top: 30px;
  div {
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #dfe1e2;
    border-right: 1px solid #dfe1e2;
    padding: 10px;
  }

  input {
    width: 100px;
    background-color: transparent;
    border: none;
    text-align: center;
  }

  h6 {
    font-size: 1em;
    color: #71787d;
    margin: 0 0 10px 0;
  }
}

.sectionContainer__window__integrationTypes__container {
  @extend .sectionContainer__window;
  border-bottom: none;
  display: block;
  h4 {
    margin-bottom: 40px;
  }

  div {
    width: 30%;
    display: flex;
    justify-content: left;

    input {
      margin-right: 30px;
    }
  }
}

.tsi__barcode__tableContainer {
  width: 97%;
  margin-top: 30px;
  background-color: white;
  height: fit-content;
  border-radius: 8px;
  margin-bottom: 60px;
}

.tsi__barcode__tableContainer__header,
.tsi__barcode__tableContainer__header__single {
  display: flex;

  width: 100%;
  justify-content: left;
  background-color: #fafafa;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 2px solid #c6c8c9;

  div {
    width: 100%;
    margin: auto;

    p {
      color: #71787d;
      font-size: 1em;
      font-weight: bold;
      margin: 8px;
      margin-left: 10px;
    }
  }
}

.tsi__barcode__tableContainer__header__single {
  justify-content: center !important;

  p {
    text-align: center;
    margin-left: 0px;
  }
}

.tsi__barcode__tableContainer__header__description {
  div {
    width: 60% !important;
    border-left: none !important;
  }
}

.tsi__barcode__tableContainer__row,
.tsi__barcode__tableContainer__row__single {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: left;
  border-bottom: 1px solid #d3d3d3;

  div {
    width: 100%;
    margin: 0px;

    p {
      color: #92979b;
      font-size: 1em;
      margin-left: 10px;
      max-width: 90%;
      word-wrap: break-word;
    }
  }
}

.tsi__barcode__tableContainer__row__single {
  border-bottom: none;

  p {
    margin-left: 0px !important;
    text-align: center !important;
  }
}

.tsi__barcode__emptyContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #71787d;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
  border-bottom: 1px dashed #71787d;
  border-right: 1px dashed #71787d;
  border-left: 1px dashed #71787d;

  p {
    text-align: center;
  }
}
