.stageTitleContainer {
  width: 100%;
  color: #71787d;
  text-align: center;

  h3 {
    font-size: 3em;
    color: #71787d;
    margin-top: 0px;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2em;
    width: 70%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }

  div {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    max-height: 300px;
    overflow: hidden;

    img {
      position: relative;
      object-position: center;

      width: 100%;
      scale: none;

      max-width: 90%;
      opacity: 0.4;
    }
  }
}

.requestStageIcon {
  color: #71787d;
  margin-top: 30px;
  width: 100px;
  height: 100px;
}

.requestContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.inputContainer {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.inputColumn {
  width: 45%;
  h4 {
    font-size: 1.8em;
    color: goldenrod;
    margin: 5px 0px 5px;
  }

  h5 {
    font-size: 1.3em;
    color: #71787d;
    margin: 3px 0px;
  }

  input {
    width: 90%;
    height: 32px;
    padding: 4px 10px;
    margin: 0px 0px 10px;
    background-color: white;
    border: 1px solid #71787d;
    border-radius: 4px;
  }
}

.errorMessage {
  color: rgb(194, 46, 46);
  font-size: 1.2em;
  text-align: center;
}
