.requestButtonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-top: 80px;
  width: 40%;

  button {
    height: 32px;
    padding: 1em 2em;
    font-size: 1.3em;
    border: none;
    border-radius: 4px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }

  button:hover {
    opacity: 0.8;
  }
}

.requestNextButton {
  background-color: goldenrod;
  color: white;
}

.requestBackButton {
  background-color: #71787d;
  color: white;
}
