.ticketingPartnerTable__mainContainer {
  width: 80%;
  height: 600px;

  border-radius: 8px;

  margin-top: 3px;
  background-color: rgb(250, 250, 250);

  h2 {
    font-size: 24px;
    text-align: center;
    color: goldenrod !important;
  }

  p {
    text-align: center;
    color: #acb0b3;
    font-size: 1em;
    margin-top: 30px;
    margin-left: 10px;
  }
}

.ticketingPartnerTableHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 60px;
}

.ticketingPartnerTable__table__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #71787d;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  div {
    width: 20%;
    height: 40px;
    justify-content: left;
    margin-left: 10px;

    display: flex;
    align-items: center;

    p {
      text-align: center;
      font-weight: bold;
      color: white;
      margin: 5px !important;
    }
  }
}

.ticketingPartnersList__rowsContainer {
  height: 400px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.ticketingPartnerTable__table__row {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  background-color: white;

  z-index: 1;

  div {
    width: 20%;
    height: 40px;
    justify-content: left;

    border-bottom: 1px solid #bdc2c5;
    display: flex;
    align-items: center;

    p {
      text-align: center;
      color: #71787d;
      margin: 5px 5px 5px 15px !important;
    }
  }
}

.ticketingPartnerTable__table__rowClicked {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  background-color: #e9e9e9;

  border-top: 2px solid #71787d;
  z-index: 1;

  div {
    width: 20%;
    height: 40px;
    justify-content: left;

    display: flex;
    align-items: center;

    p {
      text-align: center;
      color: #71787d;
      margin: 5px 5px 5px 15px !important;
    }
  }
}

.ticketingPartnerTable__table__row:hover {
  background-color: #ebeef0;
}

.ticketingPartnerTable__table__dateField {
  width: 20% !important;
}

@keyframes growDown {
  0% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0px);
  }
}

.ticketingPartnerTable__table__extendedRow {
  width: 100%;
  height: fit-content;
  background-color: #f2f3f3;
  border-bottom: 2px solid #71787d;

  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  animation: growDown 100ms ease-in-out forwards;
  cursor: pointer;
}

.ticketingPartnerTable__table__extendedHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #e9e9e9;

  div {
    width: 20%;
    height: 40px;
    justify-content: left;
    margin-left: 10px;

    display: flex;
    align-items: center;

    p {
      text-align: center;
      font-weight: bold;
      color: rgb(119, 117, 117);
      margin: 5px !important;
    }
  }
}

.ticketingPartnerTable__table__extendedRowData {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  background-color: #e9e9e9;

  div {
    width: 20%;
    height: 40px;
    justify-content: left;

    display: flex;
    align-items: center;

    p {
      text-align: center;
      color: #71787d;
      margin: 5px 5px 5px 15px !important;
    }
  }
}

.ticketingPartnerTable__table__row__address {
  height: fit-content !important;
  display: block !important;

  p {
    justify-content: left !important;
    text-align: left !important;
  }
}
