.homeTilesSection {
  width: 600px;
  height: 350px;
  padding: 30px 0;
  background-color: rgb(250, 250, 250);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  div {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 5px;
    border-radius: 8px;
    border: 2px solid #71787d;
    background-color: white;
    margin: 3px 5px 3px 5px;
    opacity: 0.8;

    cursor: pointer;

    h3 {
      text-align: center;
      cursor: pointer;
      color: #71787d;
      font-size: 14px;
    }

    h4 {
      font-size: 14px;
      color: #71787d;
      font-weight: bold;
      cursor: pointer;
    }

    p {
      max-width: 75%;
      font-size: 14px;
      text-align: center;
      padding-bottom: 20px;
      cursor: pointer;
    }
  }

  div:hover {
    opacity: 1;
    background-color: rgb(250, 250, 250);
  }
}

.icon {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  color: #71787d;

  cursor: pointer;
}

.homeTilesHeaderContainer {
  background-color: #71787d;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;

  h4 {
    color: white;
  }
}
