.addButton {
  height: 32px;
  padding: 1em 2em;
  font-size: 1.3em;
  border: none;
  border-radius: 4px;
  margin: 5px;
  margin-top: 10px;
  margin-left: 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  opacity: 1;
  background-color: goldenrod;
  color: white;

  * {
    margin-right: 10px;
  }
}

.addButton:hover {
  opacity: 0.8;
}
