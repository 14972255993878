.contactList__mainContainer {
  width: 90%;
  height: fit-content;
  min-height: 80vh;

  h2 {
    color: goldenrod;
    font-size: 28px;
  }
}

.contactList__buttonContainer {
  margin-top: 20px;

  button {
    width: 150px;
    height: 40px;
    border: none;
    background-color: transparent;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    opacity: 1;
    color: #61676b;
    font-size: 1em;
    cursor: pointer;

    * {
      margin: 3px;
    }
  }

  button:hover {
    opacity: 0.7;
  }
}

//Copied

.stageTitleContainer {
  width: 100%;
  color: #71787d;
  text-align: center;

  h3 {
    font-size: 3em;
    color: #71787d;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2em;
    width: 70%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }

  h5 {
    font-size: 1.3em;
    color: #71787d;
    margin: 3px 0px;
  }
}

.requestContentContainer {
  display: flex;
  justify-content: center;

  margin-top: 30px;
}

.inputContainer {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.inputColumn {
  width: 35%;
  h4 {
    font-size: 1.8em;
    color: goldenrod;
    margin: 5px 0px 5px;
  }

  h5 {
    font-size: 1.3em;
    color: #71787d;
    margin: 3px 0px;
  }

  input {
    width: 90%;
    height: 32px;
    padding: 4px 10px;
    margin: 0px 0px 10px;
    background-color: white;
  }
}

.icon {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  color: #71787d;

  cursor: pointer;
}

.requestTypeTilesSection {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;

  div {
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 5px;
    border-radius: 8px;
    border: 2px solid #71787d;
    background-color: white;
    margin: 3px 5px 3px 5px;
    opacity: 0.8;

    cursor: pointer;

    h3 {
      text-align: center;
      cursor: pointer;
      color: #71787d;
    }

    h4 {
      color: #71787d;
      font-weight: bold;
      cursor: pointer;
    }

    p {
      max-width: 75%;
      font-size: 14px;
      text-align: center;
      padding-bottom: 20px;
      cursor: pointer;
    }
  }

  div:hover {
    opacity: 1;
    background-color: rgb(250, 250, 250);
  }
}

.markedTile {
  opacity: 1 !important;
  background-color: rgb(250, 250, 250) !important;
  border: 2px solid goldenrod !important;
}

.errorMessage {
  color: rgb(194, 46, 46);
  font-size: 1.2em;
  text-align: center;
}

.requestNameContainer {
  margin-top: 30px;
  width: 33%;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  text-align: left;
}

.requestNameInput {
  width: 100%;
  height: 32px;
  padding: 4px 10px;
  margin: 0px 0px 10px;
  background-color: white;
  border: 1px solid #71787d;
  border-radius: 4px;
}

.requestMainCotnainer {
  width: 90%;
}

.requestContainer {
  width: 100%;
  height: fit-content;
  border: 2px solid #71787d;
  border-radius: 8px;
  padding-bottom: 40px;
  background: rgb(250, 250, 250);
}

.requestHeader {
  display: flex;
  align-items: left;
  justify-content: left;
  width: 100%;
  min-height: 200px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 60px;
  background: #71787d1e;

  h2 {
    font-size: 64px;
    text-align: center;
    color: rgb(255, 255, 255);
    z-index: 2;
  }
}

.requestHeader__container {
  position: absolute;
  height: 200px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.requestHeader__image {
  width: 100%;
  position: absolute;
  top: 0;

  div {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.493);
    z-index: 1;
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    margin-bottom: -4px;
  }
}
