.homeDescriptionSection {
  width: 100%;
  height: 505px;

  border-radius: 8px;

  margin-top: 3px;
  background-color: rgb(250, 250, 250);

  h2 {
    font-size: 24px;
    text-align: center;
    color: goldenrod !important;
  }

  p {
    text-align: center;
    color: #acb0b3;
    font-size: 1em;
    margin-top: 30px;
    margin-left: 10px;
  }
}

.currentRequestHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 60px;
  background-color: #f5f5f5;
}

.currentRequest__table__nameField {
  width: 50% !important;
  justify-content: left !important;

  p {
    text-align: left !important;
  }
}

.currentRequest__table__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #71787d;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  div {
    width: 10%;
    height: 40px;
    justify-content: center;

    display: flex;
    align-items: center;

    p {
      text-align: center;
      font-weight: bold;
      color: white;
      margin: 5px !important;
    }
  }
}

.currentRequest__table__row {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  background-color: rgb(255, 255, 255);

  div {
    width: 10%;
    height: 40px;
    justify-content: center;

    border-bottom: 1px solid #bdc2c5;
    display: flex;
    align-items: center;

    p {
      text-align: center;
      color: #71787d;
      margin: 5px !important;
    }
  }
}

.currentRequest__table__row:hover {
  background-color: #ebeef0;
}

.currentRequest__table__dateField {
  width: 20% !important;
}

.rows__container {
  overflow-y: auto;
  scrollbar-width: thin;
  height: 80%;
}

.loadingContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 18px;

    color: #71787d;
  }

  span {
    width: 50px;
    height: 50px;
    animation: rotation 1s infinite linear;
    * {
      width: 50px;
      height: 50px;
      color: goldenrod;
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
