.sectionContainer {
  width: 90%;
  display: flex;
  height: fit-content;

  justify-content: center;
  align-items: left;
  margin: 5px 0;
}

.sectionContainer__window {
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #d8d8d8;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: left;
  align-items: self-start;
  padding: 5px 0px 30px;

  h3 {
    font-size: 24px;
    margin: 15px;
    color: #71787d;
    min-width: 300px;
  }

  h4 {
    color: goldenrod;
    font-size: 18px;
    margin-bottom: 0px;
  }

  p {
    max-width: 80%;
    color: #71787d;
  }
}

.sectionContainer__sideBar {
  position: relative;
  left: 0;
  width: 30px;
  background-color: #dfdfdf;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.sectionContainer__columns {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.sectionContainer__window__contentContainer {
  border-left: 1px solid #e2e2e2;
  padding-left: 30px;
  min-height: 300px;
  width: 100%;

  textarea {
    min-width: 95%;
    max-width: 95%;
    min-height: 200px;
  }
}

.sectionContainer__customerContactPerson {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 90%;
  }
}

.sectionContainer__customerContactPerson__Row {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #b6b6b6;
}

.useCasesSummary__tableContainer {
  width: 80%;

  border-radius: 4px;
}

.useCasesSummary__tableHeader {
  display: flex;
  justify-content: left;
  background-color: #71787d;
  border-radius: 8px;

  div {
    width: 100%;
    height: 32px;

    display: flex;
    align-items: center;

    p {
      margin: 0;
      margin-left: 10px;
      font-weight: bold;
      color: white;
    }
  }
}

.useCasesSummary__tableRow {
  display: flex;
  justify-content: left;
  border-radius: 4px;
  div {
    width: 100%;
    min-height: 32px;
    border-bottom: 1px solid #d3d3d3;

    p {
      padding-right: 50px;
      padding-left: 10px;
    }
  }
}

.emptyTable {
  display: flex;
  justify-content: center;
  align-items: center;
}
